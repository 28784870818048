<template>
  <div class="mb10">
    <h2 class="content_title">{{ $t('msg.ONEX070G040.070') }}</h2><!-- 결제수단 선택 -->
    <table class="tbl_row">
      <tbody>
        <tr>
          <td class="border_left_none">
            <template v-if="isShowPayType('D')">
              <input type="radio" id="paytype-D" v-model="method.payType" value="D" :disabled="btnType === 'RETURN' && inputDefaultData.existTaxInfo === 'Y'">
              <label for="paytype-D" class="mr20"><span></span>{{ $t('msg.SETT010T010.085') }}</label><!-- 직접입금 -->
            </template>
            <template v-if="isShowPayType('V') && setRadioBtn">
              <input type="radio" id="paytype-V" v-model="method.payType" value="V" :disabled="btnType === 'RETURN' && inputDefaultData.existTaxInfo === 'Y'">
              <label for="paytype-V" class="mr20"><span></span>{{ $t('menu.MENU06.060') }}</label><!-- 가상계좌 -->
            </template>
            <template v-if="isShowPayType('N') && setRadioBtn">
              <input type="radio" id="paytype-N" v-model="method.payType" value="N" :disabled="btnType === 'RETURN' && inputDefaultData.existTaxInfo === 'Y'">
              <label for="paytype-N"><span></span>{{ $t('msg.SETT010T010.084') }} ({{ $t('msg.ADD20220219.052') }})</label><!-- 즉시이체(KB국민은행) -->
            </template>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="method.payType == 'D'">
      <!-- 직접입금 start -->
      <table class="tbl_row mt10">
        <colgroup>
          <col width="130"><col><col width="130"><col><col width="130"><col><col width="70">
        </colgroup>
        <tbody>
          <tr>
            <th colspan="6">
              <span class="color_black">{{ $t('msg.ONIM099P010.054') }} |
                <span v-show="memberDetail.userCtrCd === 'KR'" style="font-size: 12px;">    송금하신 이체증 내용과 동일하게 입력하시기 바랍니다.</span>
              </span>
            </th><!-- 은행 입금 정보 -->
            <th style="text-align: center">
              <span v-if="btnType !== 'RETURN' || inputDefaultData.existTaxInfo !== 'Y'" @click="addDepositInfo()" style="cursor: pointer; font-weight: bold; font-size: 20px; color: #075bb9;">+</span>
            </th>
          </tr>
          <template v-for="(di, i) in method.depositInfoArr">
            <tr :key="'deposit-info-1-' + i">
              <th>{{ $t('msg.ONIM099P010.055') }}</th><!-- 계좌정보 -->
              <td>
                <select v-model="di.payCur" @change="changePayCur(i)" :id="'fbt-payCur-' + di.rowIdx" :disabled="btnType === 'RETURN' && inputDefaultData.existTaxInfo === 'Y'">
                  <option value="">{{ $t('msg.ONIM099P010.056') }}</option><!-- 화폐단위선택 -->
                  <option v-for="(unit, j) in bankInfoArr" :key="'monetaryUnit-' + i + '-' + j">{{ unit.paycur }}</option>
                </select>
              </td>
              <td colspan="2">
                <select v-model="di.bank" @change="changeBank(i)" :id="'fbt-bank-' + di.rowIdx" :disabled="btnType === 'RETURN' && inputDefaultData.existTaxInfo === 'Y'">
                  <option value="">{{ $t('msg.ONIM099P010.057') }}</option><!-- 은행선택 -->
                  <option v-for="(bank, j) in di.bankArr" :key="'bank-'+ i + '-' + j">{{ bank.bank }}</option>
                </select>
              </td>
              <td colspan="2">
                <select v-model="di.accNo" :id="'fbt-accNo-' + di.rowIdx" :disabled="btnType === 'RETURN' && inputDefaultData.existTaxInfo === 'Y'">
                  <option value="">{{ $t('msg.ONIM099P010.058') }}</option><!-- 계좌번호선택 -->
                  <option v-for="(accNo, j) in di.accNoArr" :key="'accNo-' + i + '-' + j" :value="accNo.PAY_ACCT_NO">{{ accNo.ACCT_NM }}</option>
                </select>
              </td>
              <td rowspan="2" class="text-center">
                <span :style="i == 0 ? '' : 'cursor: pointer;'" @click="deleteDepositInfo(i)">
                  <template v-if="i > 0">
                    <span style="cursor: pointer; font-weight: bold; font-size: 20px; color: #075bb9;">-</span>
                  </template>

                </span>
              </td>
            </tr>
            <tr :key="'deposit-info-2-' + i">
              <th class="acc-tr-border-bottom">{{ $t('msg.ONIM099P010.059') }}</th><!-- 입금자 -->
              <td class="acc-tr-border-bottom"><input type="text" v-model="di.depositPnm" :id="'fbt-depositPnm-' + di.rowIdx" :disabled="btnType === 'RETURN' && inputDefaultData.existTaxInfo === 'Y'"></td>
              <th class="acc-tr-border-bottom">{{ $t('msg.ONIM099P010.060') }}</th><!-- 입금금액 -->
              <td class="acc-tr-border-bottom">
                <e-input-number
                  v-model="di.depositAmt"
                  :id="'fbt-depositAmt-' + di.rowIdx"
                  :is-comma="true"
                  :disabled="btnType === 'RETURN' && inputDefaultData.existTaxInfo === 'Y'"
                >
                </e-input-number>
              </td>
              <th class="acc-tr-border-bottom" v-show="btnType != 'RETURN'">{{ $t('msg.ONIM099P010.061') }}</th><!-- 입금일 -->
              <td class="acc-tr-border-bottom" @click="setCurrIdx(i)" v-show="btnType != 'RETURN'">
                <e-date-picker @input="chnageDepositDt" v-model="di.depositDt" :date-range="dateRange" :id="'fbt-depositDt-' + i" />
              </td>
            </tr>
          </template>
          <tr v-show="isOverseasFile">
            <th class="acc-tr-border-bottom">{{ $t('msg.MYIN010G010.037') }}</th><!-- 파일첨부 -->
            <td colspan="6" class="acc-tr-border-bottom">
              <input type="text" class="upload_name" v-model="uploadFileName" readonly @click="openFileUploader()">
              <a :class="uploadFileInfo.length > 0 ? 'button sm blue' : 'button sm gray'" href="javascript:void(0)" @click="openFileUploader()">{{ $t('msg.MYIN010G010.037') }}</a>
              <span id="fbt-file"></span>
            </td>
          </tr>
        </tbody>
      </table>
      <div v-show="memberDetail.userCtrCd === 'KR'" style="color: red; font-weight: bold;">※ 「입금자」는 이체증의 ‘통장표시(메모)’와 일치해야 합니다. </div>
    </div><!-- 직접입금 end -->
    <template v-else-if="method.payType == 'V'">
      <div>
        <!-- 가상계좌 start -->
        <table class="tbl_row mt10">
          <colgroup>
            <col width="130"><col>
          </colgroup>
          <tbody>
            <tr>
              <th colspan="2"><span class="color_black">{{ $t('msg.ONEX070G100.017') }}</span></th><!-- 가상 계좌 정보 -->
            </tr>
            <tr>
              <th>{{ $t('msg.SETT010T010.119') }}</th><!-- USD 계좌 -->
              <td v-if="!isEmpty(method.vrAccount.usd)">{{ method.vrAccount.usd.bankName }}  {{ method.vrAccount.usd.vrAcctNo }}</td>
              <td v-else><a class="button sm" @click="openPopup('VtlAccPop')" href="javascript:void(0)">{{ $t('msg.SETT010T010.122') }}</a></td><!-- 가상 계좌 발급 -->
            </tr>
            <tr>
              <th>{{ $t('msg.SETT010T010.120') }}</th><!-- 원화 계좌 -->
              <td v-if="!isEmpty(method.vrAccount.krw)">{{ method.vrAccount.krw.bankName }}  {{ method.vrAccount.krw.vrAcctNo }}</td>
              <td v-else><a class="button sm" @click="openPopup('VtlAccPop')" href="javascript:void(0)">{{ $t('msg.SETT010T010.122') }}</a></td><!-- 가상 계좌 발급 -->
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <!--
    <div v-else-if="method.payType == 'N'">-->
    <!-- 즉시이체 start -->
    <!--<table class="tbl_row mt10">
        <colgroup>
          <col width="130"><col width="45%"><col width="120"><col width="55%">
        </colgroup>
        <tbody>
          <tr>
            <th colspan="2"><span class="color_black">국민은행 {{ $t('msg.ONIM099P010.055') }}</span></th>--><!-- 국민은행 계좌 정보 -->
    <!--<th colspan="2"><span class="color_black">{{ $t('msg.SETT010T010.123') }}</span></th>--><!-- 즉시이체 계좌정보 -->
    <!--</tr>
          <tr>
            <th>ID</th>
            <td><input type="text" v-model="bankLoginInfo.bnkId"></td>
            <th>{{ $t('msg.SETT010T010.119') }}</th>--><!-- USD 계좌 -->
    <!--<td></td>
          </tr>
          <tr>
            <th>PW</th>
            <td><input type="password" v-model="bankLoginInfo.bnkPswd"></td>
            <th>{{ $t('msg.SETT010T010.120') }}</th>--><!-- 원화 계좌 -->
    <!--<td>신한 26-20-400014-9</td>
          </tr>
          <tr>
            <th>계좌번호</th>--><!-- 계좌번호 -->
    <!--<td><input type="text" v-model="bankLoginInfo.bnkAcct"></td>
            <td colspan="2"></td>
          </tr>
        </tbody>
      </table>
    </div>--><!-- 즉시이체 end -->

    <div v-else-if="method.payType == 'N'" class="mt10">
      <!-- 즉시이체 start -->
      <table v-if="false" class="tbl_row mt10">
        <colgroup>
          <col width="130"><col width="45%"><col width="120"><col width="55%">
        </colgroup>
        <tbody>
          <tr>
            <th colspan="2"><span class="color_black">국민은행 {{ $t('msg.ONIM099P010.055') }}</span></th><!-- 국민은행 계좌 정보 -->
            <th colspan="2"><span class="color_black">{{ $t('msg.SETT010T010.123') }}</span></th><!-- 즉시이체 계좌정보 -->
          </tr>
          <tr>
            <th>ID</th>
            <td><input type="text" v-model="bankLoginInfo.bnkId"></td>
            <th>{{ $t('msg.SETT010T010.119') }}</th><!-- USD 계좌 -->
            <td></td>
          </tr>
          <tr>
            <th>PW</th>
            <td><input type="password" v-model="bankLoginInfo.bnkPswd"></td>
            <th>{{ $t('msg.SETT010T010.120') }}</th><!-- 원화 계좌 -->
            <td>신한 26-20-400014-9</td>
          </tr>
          <tr>
            <th>계좌번호</th><!-- 계좌번호 -->
            <td><input type="text" v-model="bankLoginInfo.bnkAcct"></td>
            <td colspan="2"></td>
          </tr>
        </tbody>
      </table>

      <p v-show="bankLoginInfo.bnkCmsYn !== 'Y'" class="txt_desc none">{{ $t('msg.SETT010T010.160') }}</p><!-- 즉시간편이체 서비스를 이용하기 위해서는 KB국민은행의 기업인터넷뱅깅에 가입되고, Star CMS 및 바로 ERP에 약정이 필요합니다. -->
      <p v-show="bankLoginInfo.bnkCmsYn !== 'Y'" class="mt10"><a href="#" @click.prevent="openLinkPopup(1)"><span class="font_medium color_red">▶ {{ $t('msg.SETT010T010.161') }}</span></a></p><!-- Star CMS 및 바로 ERP 약정하러가기 (OTP/공동인증서 필요) -->
      <!-- <p v-if="bankLoginInfoOpen" class="txt_desc none mt20">1단계.KB국민은행 기업인터넷뱅킹 접속정보 <span class="color_red">등록하기</span> (최초 1회) ▲</p>
      <p v-else class="txt_desc none mt20">1단계.KB국민은행 기업인터넷뱅킹 접속정보 <span class="color_red">수정하기</span> ▼</p> -->

      <p class="txt_desc none mt20">
        1단계.KB국민은행 기업인터넷뱅킹 접속정보
        <a v-if="!bankLoginInfoOpen && bankLoginInfo.bnkCmsYn === 'Y'" href="#" @click.prevent="changeBankLoginInfoOpen()"><span class="color_red">{{ $t('msg.SETT010T010.163') }}</span> ▼</a><!-- 수정하기 -->
        <a v-else-if="!bankLoginInfoOpen && bankLoginInfo.bnkCmsYn !== 'Y'" href="#" @click.prevent="changeBankLoginInfoOpen()"><span class="color_red">{{ $t('msg.SETT010T010.164') }}</span> ▼</a><!-- 확인하기 -->
        <a v-else-if="bankLoginInfoOpen && bankLoginInfo.bnkCmsYn === 'Y'" href="#" @click.prevent="changeBankLoginInfoOpen()"><span class="color_red">{{ $t('msg.SETT010T010.163') }}</span> ▲</a><!-- 수정하기 -->
        <a v-else href="#" @click.prevent="changeBankLoginInfoOpen()"><span class="color_red">{{ $t('msg.SETT010T010.165') }}</span> ({{ $t('msg.SETT010T010.166') }}) ▲</a><!-- 등록하기 --><!-- 최초 1회 -->
      </p>

      <table v-show="bankLoginInfoOpen" class="tbl_row mt10">
        <colgroup>
          <col width="305"><col width="339"><col>
        </colgroup>
        <tbody>
          <tr>
            <th>{{ $t('msg.SETT010T010.167') }}</th><!-- KB기업뱅킹 ID -->
            <td>
              <input type="text" v-model="bankLoginInfo.bnkId">
            </td>
            <td rowspan="3" class="text_center">
              <!-- <template v-if="bankLoginInfo.bnkCmsYn === 'Y'">
                등록완료
              </template> -->
              <button v-if="bankLoginInfo.bnkCmsYn === 'Y'" type="button" class="button lg blue" @click="kbErpSave()">
                {{ $t('msg.SETT010T010.163') }}
              </button><!-- 수정하기 -->
              <button v-else type="button" class="button lg blue" @click="kbErpSave()">
                {{ $t('msg.SETT010T010.165') }}
              </button><!-- 등록하기 -->
            </td>
          </tr>
          <tr>
            <th>{{ $t('msg.SETT010T010.168') }}</th><!-- KB기업뱅킹 사용자 암호 -->
            <td>
              <input type="text" v-model="bankLoginInfo.bnkPswd">
            </td>
          </tr>
          <tr>
            <th>{{ $t('msg.SETT010T010.169') }}</th><!-- KB Star CMS 및 바로 ERP 약정 -->
            <td class="text-center">
              <template v-if="bankLoginInfo.bnkCmsYn === 'Y'">
                {{ $t('msg.SETT010T010.170') }}
              </template><!-- 약정완료 -->
              <template v-else>
                {{ $t('msg.SETT010T010.171') }}
              </template><!-- 확인중 -->
            </td>
          </tr>
        </tbody>
      </table>
      <p v-show="bankLoginInfo.bnkCmsYn !== 'Y'" class="mt5 txt_desc none color_blue">※ {{ $t('msg.SETT010T010.172') }},(<a href="#" @click.prevent="openLinkPopup(2)">{{ $t('msg.SETT010T010.164') }}</a>)</p><!-- KB 국민은행 기업인터넷뱅킹 ID/PW를 모르실 경우 --><!-- 확인하기 -->
      <p v-show="bankLoginInfo.bnkCmsYn !== 'Y'" class="txt_desc none color_blue">※ {{ $t('msg.SETT010T010.173') }} (<a href="#" @click.prevent="openLinkPopup(3)">{{ $t('msg.SETT010T010.174') }}</a>)</p><!-- KB 국민은행 Star CMS 및 바로 ERP 상태가 미약정인 경우,'약정하기'로 이동하여,OTP/공동인증서로 약정바랍니다. --><!-- 약정하기 가이드 -->

      <!-- <p class="txt_desc none mt20">2단계:KB국민은행 출금계좌 <span class="color_red">등록하기</span> (변경가능)</p> -->

      <p class="txt_desc none mt20">
        {{ $t('msg.SETT010T010.175') }}<!-- 2단계:KB국민은행 출금계좌 -->
        <a v-if="!bankLoginInfoAcctOpen && bankLoginInfo.bnkCmsYn === 'Y'" href="#" @click.prevent="changeBankLoginInfoAcctOpen()"><span class="color_red">{{ $t('msg.SETT010T010.163') }}</span> ▼</a><!-- 수정하기 -->
        <a v-else-if="!bankLoginInfoAcctOpen && bankLoginInfo.bnkCmsYn !== 'Y'" href="#" @click.prevent="changeBankLoginInfoAcctOpen()"><span class="color_red">{{ $t('msg.SETT010T010.165') }}</span> ({{ $t('msg.SETT010T010.176') }}) ▼</a><!-- 등록하기 --><!-- 변경가능 -->
        <a v-else-if="bankLoginInfoAcctOpen && bankLoginInfo.bnkCmsYn === 'Y'" href="#" @click.prevent="changeBankLoginInfoAcctOpen()"><span class="color_red">{{ $t('msg.SETT010T010.163') }}</span> ▲</a><!-- 수정하기 -->
        <a v-else href="#" @click.prevent="changeBankLoginInfoAcctOpen()"><span class="color_red">{{ $t('msg.SETT010T010.165') }}</span> ({{ $t('msg.SETT010T010.176') }}) ▲</a><!-- 등록하기 --><!-- 변경가능 -->
      </p>

      <table v-show="bankLoginInfoAcctOpen" class="tbl_row mt10">
        <colgroup>
          <col width="305"><col width="339"><col>
        </colgroup>
        <tbody>
          <tr>
            <th>{{ $t('msg.SETT010T010.178') }}</th><!-- >KB국민은행 출금계좌 -->
            <td>
              <select v-model="params.bnkAcct" @change.prevent="changeBnkAcct()">
                <option
                  v-for="option in drwotAcnInfo.drwotAcnList"
                  :key="option.drwotAcno"
                  :value="option.drwotAcno"
                  :selected="option.drwotAcno === bankLoginInfo.bnkAcct"
                >
                  {{ option.drwotAcno }}
                </option>
              </select>
            </td>
            <td rowspan="2" class="text_center"><button type="button" class="button lg blue" @click.prevent="updateDrwotAcnInfoBnkAcct()">{{ $t('msg.SETT010T010.165') }}</button></td><!-- 등록하기 -->
          </tr>
        </tbody>
      </table>
      <p class="mt5 txt_desc none color_blue">※ {{ $t('msg.SETT010T010.178') }}</p><!-- 출금계좌를 변경할 경우에도 선택하여 등록하기 버튼 클릭하시면 됩니다. -->
      <div class="mt20">
        <!-- <a class="button sm" href="#">KB StarCMS 바로 ERP 동의 </a> -->
        <a class="link black" href="#">{{ $t('msg.SETT010T010.179') }} </a><!-- 고려해운 이용 가이드 -->
        <a class="link black ml10" href="#">{{ $t('msg.SETT010T010.180') }} </a><!-- KB StarCMS 가입안내 -->
      </div>
    </div><!-- 즉시이체 end -->

    <div id="vtl_pop">
      <win-layer-pop>
        <component
          v-if="customComponent"
          :is="customComponent"
          @close="closePopup"
          @child="getChildData"
          :parent-info="parentInfo"
          :freight-bill-info="parentInfo"
        />
      </win-layer-pop>
    </div>
    <div id="fb_dext5_pop2" v-show="isShowDext5Pop">
      <div class="popup_dim" ref="popup">
        <div class="popup_wrap" style="width:800px; height:520px;">
          <button class="layer_close" @click="closeFileUploadr()">close</button>
          <div class="popup_cont">
            <h1 class="page_title">{{ $t('msg.SETT010T010.181') }}</h1><!-- 파일첨부 -->
            <div class="content_box">
              <DEXT5Upload
                ref="dextupload"
                v-if="isShowDext5"
                :id="'dext5-uploader-fb2-' + dext5Idx"
                :single="false"
                :category="false"
                width="100%"
                height="200px"
                @completed="uploadFile"
                :file-info="uploadFileInfo"
                :category-info="[]"
              />
            </div>
          </div>
          <div class="mt10 text_center">
            <a class="button blue lg" @click="closeFileUploadr()">{{ $t('msg.MYIN050T010.057') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CalcIssue from '@/api/rest/settle/calcIssue'
import EDatePicker from '@/components/common/EDatePickerCustom'
import WinLayerPop from '@/pages/trans/popup/WinLayerPop'
import VtlAccPop from '@/pages/settle/popup/VtlAccPop'
import EInputNumber from '@/components/common/EInputNumber'
import { rootComputed } from '@/store/helpers'
import DEXT5Upload from '@/components/DEXT5Upload'
export default {
  name: 'FreightBillMethod',
  components: {
    EDatePicker,
    WinLayerPop,
    VtlAccPop,
    EInputNumber,
    DEXT5Upload
  },
  props: [
    'errArr',
    'clearArr',
    'validationMethod',
    'dpId',
    'inputDefaultData',
    'btnType'
  ],
  data: function () {
    return {
      dateRange: {
        min: '',
        max: ''
      },
      method: {
        payType: 'D', // D: "직접입금", V: "가상계좌", N : "즉시이체"
        vrAccount: { usd: null, krw: null },
        depositInfoArr: [{
              payCur: '',
              bank: '',
              bankArr: [],
              accNo: '',
              accNoArr: [],
              depositPnm: '',
              depositAmt: '',
              depositAmtComma: '',
              depositDt: this.dateFormat(new Date(), false),
              rowIdx: 0,
              inputError: false
        }]
      },
      bankInfoArr: [],
      bankArr: [],
      accNoArr: [],
      payCur: [],
      currIdx: null,
      rowIdx: 0,
      bankLoginInfo: {
        bnkId: '',
        bnkPswd: '',
        bnkAcct: '',
        bnkCmsYn: ''
      },
      customComponent: null,
      parentInfo: null,
      isShowTooltip: false,
      inputError: false,
      uploadFileInfo: [],
      uploadFileName: '',
      dext5Idx: 0,
      isShowDext5: true,
      isShowDext5Pop: true,
      isOverseasFile: true,
      drwotAcnInfo: {
        rtnCode: '',
        drwotAcnList: []
      },
      params: {
        bnkId: '',
        bnkPswd: '',
        bnkAcct: '',
        bnkCmsYn: ''
      },
      linkPopupPage: null,
      joinDrwotAcnInfo: null,
      bankLoginInfoOpen: true,
      bankLoginInfoAcctOpen: true
    }
  },
  computed: {
    ...rootComputed,
    setRadioBtn () {
      let rtnVal = true

      // d/o발행, dem/det, OFC, 반납, 부킹캔슬 일때, 가상계좌/즉시이체 숨김
      if (this.btnType === 'DO' || this.btnType === 'OFC' || this.btnType === 'DEMDET' || this.btnType === 'RETURN' || this.btnType === 'BOOKINGCANCEL') {
        rtnVal = false
      }

      return rtnVal
    }
  },
  watch: {
    uploadFileInfo () {
      if (this.uploadFileInfo.length > 0) {
        let fileNm = ''
        let isFirst = true
        for (let file of this.uploadFileInfo) {
          if (!isFirst) {
            fileNm += ', '
          }
          fileNm += file.originalName
          isFirst = false
        }
        this.uploadFileName = fileNm
      } else {
        this.uploadFileName = ''
      }
    },
    dpId: function () {
      //
    },
    method: {
      deep: true,
      handler () {
        if (typeof (this.validationMethod) === 'function') {
          this.validationMethod(this.method, 'inputData')
        }

        this.$emit('child', { type: 'updateMethodPayType', payload: { payType: this.method.payType } })
      }
    },
    errArr: {
      deep: true,
      handler () {
        for (let err of this.errArr) {
          if (err.id.indexOf('fbt-depositDt') !== -1) {
            this.method.depositInfoArr[err.id.split('-')[2]].inputError = true
            this.$ekmtcCommon.showErrorTooltip(document.querySelector('#' + err.id), err.msg)
          } else {
            this.$ekmtcCommon.showErrorTooltip(document.querySelector('#' + err.id), err.msg)
          }
        }
      }
    },
    clearArr: {
      deep: true,
      handler () {
        for (let id of this.clearArr) {
          if (id.indexOf('fbt-depositDt') !== -1) {
            this.method.depositInfoArr[id.split('-')[2]].inputError = false
            this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#' + id))
          } else {
            this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#' + id))
          }
        }
      }
    }
  },
  created () {
    this.resetDext5()
    this.isOverseasFile = this.memberDetail.userCtrCd !== 'KR'
  },
  mounted: function () {
     this.getVrAccount()
     this.getBankInfo()
     this.getBankLoginInfo()
     this.setToday()
  },
  methods: {
    setToday () {
      this.dateRange = {
        min: '',
        max: this.dateFormat(new Date(), true)
      }
    },
    dateFormat (dt, isDot) {
      let year = dt.getFullYear() //연도
      let month = ('0' + (dt.getMonth() + 1)).slice(-2) //월
      let date = ('0' + dt.getDate()).slice(-2) //날짜
      let res = ''
      if (isDot) {
        res = year + '.' + month + '.' + date
      } else {
        res = year + month + date
      }
      return res
    },
    resetDext5 () {
      this.isShowDext5Pop = false
      this.openFileUploader()
      this.closeFileUploadr()
      this.isShowDext5Pop = true
    },
    openFileUploader () {
      this.isShowDext5 = true
      this.dext5Idx++
      this.$ekmtcCommon.layerOpen('#fb_dext5_pop2 > .popup_dim')
    },
    closeFileUploadr () {
      this.isShowDext5 = false
      this.$ekmtcCommon.layerClose('#fb_dext5_pop2 > .popup_dim')
    },
    uploadFile (fileInfo) {
      this.uploadFileInfo[0].isNew = true
    },
    getChildData (data) {
       switch (data.type) {
        case 'close':
          this.closePopup()
          break
       }
    },
    openPopup (compNm) {
        this.customComponent = compNm
        this.$ekmtcCommon.layerOpen('#vtl_pop > .popup_dim')
    },
    closePopup (obj) {
        this.customComponent = null
        this.$ekmtcCommon.layerClose('#vtl_pop > .popup_dim')
        this.getVrAccount()
    },
    isShowPayType (type) {
      let isShow = true
      if (type === 'D') {
        //
      } else if (type === 'V') {
        if (this.dpId === 'SETT060G010') {
          //isShow = false
        }
      } else if (type === 'N') {
        if (this.dpId === 'SETT060G010') {
          //isShow = false
        }
      }
      return isShow
    },
    async getBankLoginInfo () {
      await CalcIssue.getBankLoginInfo().then(response => {
        this.bankLoginInfo = { ...response.data }
        console.log('this.bankLoginInfo Info--------')
        console.log(this.bankLoginInfo)

        if (this.bankLoginInfo !== null && this.bankLoginInfo.bnkId !== null && this.bankLoginInfo.bnkPswd != null) {
          CalcIssue.getDrwotAcnInfo(this.bankLoginInfo.bnkId, this.bankLoginInfo.bnkPswd).then(response2 => {
            // 로그인 에러
            if (response2.data.drwotAcnListOutInfo.rtnCode === '0000') {
              if (response2.data.drwotAcnListRecList !== null || response2.data.drwotAcnListRecList.length > 0) {
                this.drwotAcnInfo.drwotAcnList = response2.data.drwotAcnListRecList
                if (this.bankLoginInfo !== null && this.bankLoginInfo.bnkAcct !== null && this.bankLoginInfo.bnkAcct !== '') {
                  this.params.bnkAcct = this.bankLoginInfo.bnkAcct
                } else {
                  this.params.bnkAcct = this.drwotAcnInfo.drwotAcnList[0].drwotAcno
                }
              }
            } else {
              if (response2.data.rtnCode === 'CAVVA975') {
                console.log('CAVVA975')
              }
            }

            this.bankLoginInfoOpen = false
            this.bankLoginInfoAcctOpen = false
          })
        }
      }).catch(e => {
        console.log(e)
      })
    },
    getVrAccount () {
       CalcIssue.getVrAccount().then(response => {
         this.method.vrAccount = { usd: {}, krw: {} }
         for (let vrAccount of response.data) {
           if (vrAccount.currencyCode === 'WON') {
             this.method.vrAccount.krw = vrAccount
           } else if (vrAccount.currencyCode === 'USD') {
             this.method.vrAccount.usd = vrAccount
           }
         }
      }).catch(e => {
        console.log(e)
      })
    },
    getBankInfo () {
      CalcIssue.getBankInfo().then(response => {
        this.bankInfoArr = response.data
        this.setInputData()
      }).catch(e => {
        console.log(e)
      })
    },
    async setInputData () {
      if (this.isEmpty(this.inputDefaultData)) {
        //
      } else {
        if (this.inputDefaultData.setlMethCd !== undefined && this.inputDefaultData.setlMethCd !== null && this.inputDefaultData.setlMethCd !== '') {
          this.method.payType = this.inputDefaultData.setlMethCd
        }

        let depositInfoArr = JSON.parse(JSON.stringify(this.inputDefaultData.payInfoList))
        if (!this.isEmpty(depositInfoArr)) {
          this.method.depositInfoArr = []
          for (let d of depositInfoArr) {
            let selectedArr = await this.getBankInfoArr(d.payCur, d.rcvBnkNm)
            let obj = {
              payCur: d.payCur || '',
              bank: d.rcvBnkNm || '',
              bankArr: selectedArr.bankArr,
              accNo: d.payAcctNo || '',
              accNoArr: selectedArr.accNoArr,
              depositPnm: d.rcvUsrNm,
              depositAmt: '',
              depositAmtComma: this.numberFormat(d.payAmt),
              depositDt: d.rcvDt,
              rowIdx: this.rowIdx,
              inputError: false
            }

            this.method.depositInfoArr.push(obj)
            this.setComma(this.rowIdx)
            this.rowIdx++
          }
        }
      }
    },
    getBankInfoArr (payCur, bankName) {
      let res = {
        bankArr: [],
        accNoArr: []
      }
      for (let p of this.bankInfoArr) {
        if (payCur === p.paycur) {
          res.bankArr = p.bankList
          for (let b of p.bankList) {
            if (bankName === b.bank) {
              res.accNoArr = b.accNoList
              break
            }
          }
          break
        }
      }

      return res
    },
    changePayCur (idx) {
      let payCur = this.method.depositInfoArr[idx].payCur
      let bankArr = []
      this.method.depositInfoArr[idx].bank = ''
      this.method.depositInfoArr[idx].bankArr = []
      this.method.depositInfoArr[idx].accNo = ''
      this.method.depositInfoArr[idx].accNoArr = []
      for (let p of this.bankInfoArr) {
        if (payCur === p.paycur) {
          this.method.depositInfoArr[idx].bankArr = p.bankList
          break
        }
      }
    },
    changeBank (idx) {
      let bank = this.method.depositInfoArr[idx].bank
      let payCur = this.method.depositInfoArr[idx].payCur
      this.method.depositInfoArr[idx].accNo = ''
      this.method.depositInfoArr[idx].accNoArr = []
      for (let p of this.bankInfoArr) {
        if (payCur === p.paycur) {
          for (let b of p.bankList) {
            if (bank === b.bank) {
              this.method.depositInfoArr[idx].accNoArr = b.accNoList
              break
            }
          }
        }
      }
    },
    addDepositInfo () {
      if (this.method.depositInfoArr.length >= 3) {
        let obj = {
            alertType: 'simple',
            customCloseBtnClass: 'button blue lg simplert-test1',
            customCloseBtnText: this.$t('msg.ONEX020P130.068'), //'닫기',
            useIcon: false,
            customClass: 'simple_alert',
            message: this.$t('msg.ADD20220219.018'), // 입금정보는 최대 3개까지 추가할 수 있습니다.
            type: 'info'
          }
          this.$ekmtcCommon.alert(obj)
      } else {
        this.rowIdx++
        this.method.depositInfoArr.push(
        {
          payCur: '',
          bank: '',
          bankArr: [],
          accNo: '',
          accNoArr: [],
          depositPnm: '',
          depositAmt: '',
          depositAmtComma: '',
          depositDt: this.dateFormat(new Date(), false),
          rowIdx: this.rowIdx,
          inputError: false
        }
      )
      }
    },
    deleteDepositInfo (idx) {
      if (idx > 0) {
         this.method.depositInfoArr.splice(idx, 1)
      }
    },
    setCurrIdx (idx) {
      this.currIdx = idx
    },
    chnageDepositDt () {
      this.$emit('child', { type: 'updateDepoistDt', payload: { depositInfoArr: this.method.depositInfoArr } })
    },
    isEmpty (value) {
      if (value === '' || value === null || value === undefined || (value != null && typeof value === 'object' && !Object.keys(value).length)) {
        return true
      } else {
        return false
      }
    },
    emitData (type) {
      let res = null
      let method = null
      switch (type) {
        case 'method':
          method = JSON.parse(JSON.stringify(this.method))
          method.erpBankName = ''
          method.bankLoginInfo = this.bankLoginInfo
          method.overseasFileList = this.uploadFileInfo
          res = method
          break
      }
      return res
    },
    setComma (idx) {
      this.method.depositInfoArr[idx].depositAmt = this.method.depositInfoArr[idx].depositAmtComma.replace(/,/gi, '')
      this.method.depositInfoArr[idx].depositAmtComma = this.numberFormat(this.method.depositInfoArr[idx].depositAmt)
    },
    numberFormat (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    reset () {
      this.resetDext5()
      this.method.payType = 'D'
      this.method.depositInfoArr = [
        {
          payCur: '',
          bank: '',
          bankArr: [],
          accNo: '',
          accNoArr: [],
          depositPnm: '',
          depositAmt: '',
          depositDt: this.dateFormat(new Date(), false),
          rowIdx: 0
        }
      ]

      this.bankArr = []
      this.accNoArr = []
      this.currIdx = null
      this.rowIdx = 0
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#fbt-payCur-0'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#fbt-bank-0'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#fbt-accNo-0'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#fbt-depositPnm-0'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#fbt-depositAmt-0'))
      this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#fbt-depositDt-0'))
    },
    async updateDrwotAcnInfoBnkAcct () {
      // this.params.bnkId = this.bankLoginInfo.bnkId
      // this.params.bnkPswd = this.bankLoginInfo.bnkPswd
      // this.params.bnkCmsYn = this.bankLoginInfo.bnkCmsYn

      if (this.bankLoginInfo.bnkCmsYn !== 'Y') {
        this.openAlert(app.$t('msg.SETT010T010.182')) // Star CMS 및 바로ERP 약정을 먼저 진행 하셔야 등록이 가능합니다.
      }

      if (this.params.bnkAcct === null || this.params.bnkAcct === '') {
        this.openAlert(app.$t('msg.SETT010T010.183')) // 출금계좌를 선택해주세요.
      }

      await CalcIssue.updateDrwotAcnInfoBnkAcct(this.params).then(response => {
        this.openAlert(app.$t('msg.SETT010T010.184')) // 등록되었습니다.
      }).catch(e => {
        console.log(e)
      })
    },
    openLinkPopup (cnt) {
      if (cnt === 1) {
        this.linkPopupPage = window.open('https://cms.kbstar.com/quics?page=C064285')
      } else if (cnt === 2) {
        this.linkPopupPage = window.open('https://obiz.kbstar.com/quics?page=C017207')
      } else if (cnt === 3) {
        this.linkPopupPage = window.open('http://www.ekmtc.com/KMTC410/InformDetail.do?notice_id=529&action=view&table_id=A')
      }

      this.linkPopupPage.addEventListener('beforeunload', this.closeLinkPopup)
    },
    closeLinkPopup () {
      if (this.linkPopupPage) {
        this.linkPopupPage.close()
        this.linkPopupPage.removeEventListener('beforeunload', this.closeLinkPopup)
        this.linkPopupPage = null
      }
    },
    kbErpSave () {
      if (typeof this.bankLoginInfo.bnkId === 'undefined' || this.bankLoginInfo.bnkId === null || this.bankLoginInfo.bnkId === '') {
        this.openAlert(app.$t('msg.SETT010T010.185')) // KB 기업인터넷뱅킹 ID를 입력해주세요.

        return
      }

      if (typeof this.bankLoginInfo.bnkPswd === 'undefined' || this.bankLoginInfo.bnkPswd === null || this.bankLoginInfo.bnkPswd === '') {
        this.openAlert(app.$t('msg.SETT010T010.186')) // KB 기업인터넷뱅킹 비밀번호를 입력해주세요.

        return
      }

      this.params.bnkId = this.bankLoginInfo.bnkId
      this.params.bnkPswd = this.bankLoginInfo.bnkPswd

      CalcIssue.joinDrwotAcnInfo(this.params).then(response => {
        this.joinDrwotAcnInfo = response.data.joinInfoInqOutInfo

        if (this.joinDrwotAcnInfo.rtnCode !== '0000') {
          this.openAlert(this.joinDrwotAcnInfo.rtnMsg.replace(/\+/g, ' '))
        } else {
          if (response.data.erpYn === 'Y' && response.data.starCmsYn === 'Y') {
            this.openAlert(app.$t('msg.SETT010T010.187')) // 정상적으로 등록되었습니다.

            this.bankLoginInfo.bnkCmsYn = 'Y'
            this.getBankLoginInfo()
          } else {
            this.openAlert(app.$t('msg.SETT010T010.188')) // Star CMS 및 바로ERP 약정을 먼저 진행 하셔야 등록이 가능합니다.
            this.openLinkPopup(1)
          }
        }
      }).catch(e => {
        console.log(e)
      })
    },
    changeBankLoginInfoOpen () {
      this.bankLoginInfoOpen = !this.bankLoginInfoOpen
    },
    changeBankLoginInfoAcctOpen () {
      this.bankLoginInfoAcctOpen = !this.bankLoginInfoAcctOpen
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('msg.CSBK100.137'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    changeBnkAcct () {
      this.bankLoginInfo.bnkAcct = this.params.bnkAcct
    }
  }
}
</script>
<style>
  .acc-tr-border-bottom {
   border-bottom: 1px solid #e1e3eb
  }
</style>
