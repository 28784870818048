var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "layer-invoice-publish" } },
    [
      _c(
        "win-layer-pop",
        { staticClass: "layer-sub-invoice-publish" },
        [
          _vm.custCompInvoice
            ? _c(_vm.custCompInvoice, {
                tag: "component",
                attrs: {
                  "is-show-pop": _vm.isInvoiceShowPop,
                  "parent-info": _vm.invoiceParam,
                },
                on: { close: _vm.closeInvoce },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }