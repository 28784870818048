var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mb10" },
    [
      _c("h2", { staticClass: "content_title" }, [
        _vm._v(" " + _vm._s(_vm.$t("msg.SETT010T010.107")) + " "),
      ]),
      _c("div", { style: _vm.headerFooterCss }, [
        _c("table", { staticClass: "tbl_col" }, [
          _vm._m(0),
          _c("thead", [
            _c("tr", [
              _c("th", { staticClass: "bb" }, [_vm._v("B/L No.")]),
              _c("th", { staticClass: "bb" }, [
                _vm._v(_vm._s(_vm.$t("msg.ADD20220219.017"))),
              ]),
              _vm._v(" "),
              _c("th", { staticClass: "bb" }, [_vm._v("Currency")]),
              _c("th", { staticClass: "bb" }, [
                _vm._v(_vm._s(_vm.$t("msg.CSDBL400_M2.0042")) + " "),
              ]),
              _vm._v(" "),
              _c("th", { staticClass: "bb" }, [_vm._v("Invoice")]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { attrs: { id: "fbp-body-table-div" } }, [
        _c("table", { staticClass: "tbl_col" }, [
          _c(
            "tbody",
            [
              _vm._l(_vm.info, function (row, i) {
                return [
                  _c("tr", { key: "fbp-row1-" + i }, [
                    _c(
                      "td",
                      {
                        staticClass: "bb",
                        staticStyle: { width: "22%" },
                        attrs: { rowspan: "2" },
                      },
                      [_vm._v(_vm._s(row.blNo))]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "bb",
                        staticStyle: { width: "22%" },
                        attrs: { rowspan: "2" },
                      },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: row.currency,
                                expression: "row.currency",
                              },
                            ],
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    row,
                                    "currency",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                                function ($event) {
                                  return _vm.cahngeCurrency(i)
                                },
                              ],
                            },
                          },
                          _vm._l(_vm.currency, function (c, j) {
                            return _c(
                              "option",
                              {
                                key: "fpb-row-" + i + "-select-option-" + j,
                                domProps: { value: c },
                              },
                              [_vm._v(_vm._s(c))]
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                    _c("td", { staticStyle: { width: "20%" } }, [
                      _vm._v("KRW"),
                    ]),
                    _c(
                      "td",
                      {
                        staticClass: "text_right",
                        staticStyle: { width: "20%" },
                      },
                      [
                        row.isCollectBl
                          ? [_vm._v(" ** ")]
                          : [
                              _vm._v(
                                " " + _vm._s(_vm.numberFormat(row.exKrw)) + " "
                              ),
                            ],
                      ],
                      2
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "bb",
                        staticStyle: { width: "16%" },
                        attrs: { rowspan: "2" },
                      },
                      [
                        !_vm.isEmpty(row.invIssNo) &&
                        !_vm.isEmpty(row.invRno) &&
                        !row.isCollectBl
                          ? [
                              _c(
                                "a",
                                {
                                  staticClass: "button sm",
                                  attrs: { href: "javascript:void(0)" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openInvoicePop(i)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.$t("msg.MYIN040G010.029")))]
                              ),
                            ]
                          : _vm._e(),
                        !_vm.isEmpty(row.invIssNo) &&
                        !_vm.isEmpty(row.invRno) &&
                        row.isCollectBl
                          ? [
                              _c(
                                "a",
                                {
                                  staticClass: "button sm",
                                  staticStyle: {
                                    "background-color": "darkgray",
                                  },
                                  attrs: { href: "javascript:void(0)" },
                                },
                                [_vm._v(_vm._s(_vm.$t("msg.MYIN040G010.029")))]
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                  _c("tr", { key: "fbp-row2-" + i }, [
                    _c(
                      "td",
                      {
                        staticClass: "border_left bb",
                        staticStyle: { width: "20%" },
                      },
                      [_vm._v("USD")]
                    ),
                    _c(
                      "td",
                      {
                        staticClass: "text_right bb",
                        staticStyle: { width: "20%" },
                      },
                      [
                        row.isCollectBl
                          ? [_vm._v(" ** ")]
                          : [
                              _vm._v(
                                " " + _vm._s(_vm.numberFormat(row.exUsd)) + " "
                              ),
                            ],
                      ],
                      2
                    ),
                  ]),
                ]
              }),
            ],
            2
          ),
        ]),
      ]),
      _c("div", { style: _vm.headerFooterCss }, [
        _c("table", { staticClass: "tbl_col" }, [
          _vm._m(1),
          _c("tfoot", [
            _c("tr", [
              _c("th", { attrs: { rowspan: "2" } }, [_vm._v("Total")]),
              _c("th", { attrs: { rowspan: "2" } }, [
                _vm._v(
                  "B/L " +
                    _vm._s(_vm.info.length) +
                    _vm._s(_vm.$t("msg.UTIL010G010.010"))
                ),
              ]),
              _c("th", [_vm._v("KRW")]),
              _c("th", { staticClass: "text_right" }, [
                _vm._v(_vm._s(_vm.numberFormat(_vm.krwTotal))),
              ]),
              _c("th", { attrs: { rowspan: "2" } }),
            ]),
            _c("tr", [
              _c("th", { staticClass: "border_left" }, [_vm._v("USD")]),
              _c("th", { staticClass: "text_right" }, [
                _vm._v(_vm._s(_vm.numberFormat(_vm.usdTotal))),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("invoice-publish-pop", { ref: "invoicePub" }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "22%" } }),
      _c("col", { staticStyle: { width: "22%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "16%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "22%" } }),
      _c("col", { staticStyle: { width: "22%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "16%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }